import { createApi } from '@reduxjs/toolkit/query/react';
import { PageUserDto, UserDto } from 'dtos';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';

type UploadFilterCombinationsParams = {
  formData: FormData;
  userId: number;
};

export const crmAssistantsApi = createApi({
  reducerPath: 'crmAssistantsApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['CrmAssistant'],
  endpoints: (builder) => ({
    getCrmAssistants: builder.query<UserDto[], void>({
      query: () => '/crm/assistants',
      providesTags: [{ type: 'CrmAssistant' }],
      keepUnusedDataFor: 300,
    }),

    addCrmAssistant: builder.mutation<void, UserDto['id']>({
      query: (userId) => ({
        url: '/crm/assistant',
        method: 'POST',
        params: { userId },
      }),
      invalidatesTags: [{ type: 'CrmAssistant' }],
    }),

    deleteCrmAssistant: builder.mutation<void, UserDto['id']>({
      query: (userId) => ({
        url: '/crm/assistant',
        method: 'DELETE',
        params: { userId },
      }),
      invalidatesTags: [{ type: 'CrmAssistant' }],
    }),

    uploadFilterCombinations: builder.mutation<number, UploadFilterCombinationsParams>({
      query: ({ formData, userId }) => ({
        url: '/crm/filter-combinations/upload',
        method: 'POST',
        body: formData,
        params: { userId },
      }),
      invalidatesTags: [{ type: 'CrmAssistant' }],
    }),

    getContactsWithSearch: builder.query<PageUserDto, { searchString?: string, page: number, limit: number }>({
      query: (params) => ({
        url: '/v2/contacts/crm',
        params,
      }),
      providesTags: [{ type: 'CrmAssistant' }],
    }),

    getCrmWhatsAppAccounts: builder.query<string[], void>({
      query: () => '/crm/whatsapp-accounts',
      providesTags: [{ type: 'CrmAssistant' }],
    }),

    isUserCrmOwner: builder.query<boolean, void>({
      query: () => '/crm/is-owner',
      providesTags: [{ type: 'CrmAssistant' }],
    }),
  }),
});

export const {
  useGetCrmAssistantsQuery,
  useAddCrmAssistantMutation,
  useDeleteCrmAssistantMutation,
  useUploadFilterCombinationsMutation,
  useGetContactsWithSearchQuery,
  useLazyGetContactsWithSearchQuery,
  useGetCrmWhatsAppAccountsQuery,
  useIsUserCrmOwnerQuery,
} = crmAssistantsApi; 