import styles from './index.module.scss';

import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { NOT_FOUND } from 'pages/paths';
import AssistantCard from './AssistantCard';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import React, { ChangeEvent, useCallback, useEffect, useState, useRef } from 'react';
import Input from 'components/Input/Input';
import { InfiniteList } from 'components/InfiniteList';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { getUserId } from 'shared/lib/auth';
import { 
  useGetCrmAssistantsQuery, 
  useLazyGetContactsWithSearchQuery, 
  useIsUserCrmOwnerQuery,
  useUploadFilterCombinationsMutation
} from 'services/crm-assistants';
import { CsvUploader } from 'components/CsvUploader';

const INITIAL_PAGE = 1;
const INITIAL_LIMIT = 10;

function CrmAssistants(): JSX.Element {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const prevAssistantsRef = useRef<any[]>([]);

  const [searchInput, setSearchInput] = useState<string>('');
  const [searchString, setSearchString] = useState<string>('');
  const [page, setPage] = useState<number>(INITIAL_PAGE);
  const [allContacts, setAllContacts] = useState<any[]>([]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetSearchString = useCallback((searchString?: string) => {
    setSearchString(searchString || '');
    setPage(INITIAL_PAGE);
    setAllContacts([]);
  }, []);
  
  const changeSelected = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    debounceSetSearchString(event.target.value);
  };

  const [fetchContacts, { data, isFetching, isSuccess }] = useLazyGetContactsWithSearchQuery();
  const isLoading = isFetching;
  const isFetchingNextPage = false;
  const hasMore = data ? !data.last : false;

  const {
    data: crmAssistants = [],
    isLoading: isLoadingCrmAssistants,
    isError,
  } = useGetCrmAssistantsQuery();

  const { data: isOwner = false } = useIsUserCrmOwnerQuery();
  const [uploadFilterCombinations, { isLoading: isUploading }] = useUploadFilterCombinationsMutation();

  useEffect(() => {
    const assistantsChanged = JSON.stringify(prevAssistantsRef.current) !== JSON.stringify(crmAssistants);
    if (assistantsChanged) {
      setPage(INITIAL_PAGE);
      setAllContacts([]);
      prevAssistantsRef.current = crmAssistants;
    }
  }, [crmAssistants]);

  useEffect(() => {
    fetchContacts({
      searchString,
      page,
      limit: INITIAL_LIMIT
    });
  }, [searchString, page, fetchContacts]);

  useEffect(() => {
    if (!data?.content) return;

    if (page === INITIAL_PAGE) {
      setAllContacts(data.content);
    } else {
      setAllContacts(prevContacts => {
        const newContacts = (data.content || []).filter(
          newContact => !prevContacts.some(existingContact => existingContact.id === newContact.id)
        );
        return [...prevContacts, ...newContacts];
      });
    }
  }, [data, page]);

  useEffect(() => {
    if (inView && hasMore && isSuccess && !isFetching) {
      setPage(prevPage => prevPage + 1);
    }
  }, [inView, hasMore, isSuccess, isFetching]);

  const handleUploadCsv = async (file: File) => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    const userIdStr = getUserId();
    const userId = userIdStr ? parseInt(userIdStr, 10) : null;

    if (!userId) {
      toast.error(t('page.crmAssistants.userIdNotFound'));
      return;
    }

    try {
      await toast.promise(uploadFilterCombinations({ formData, userId }).unwrap(), {
        loading: t('status.uploading'),
        success: () => <b>{t('page.crmAssistants.uploadSuccess')}</b>,
        error: (error) => {
          const errorMessage =
            error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    } catch (error) {
      console.error('Failed to upload filter combinations:', error);
    }
  };
  
  const indexOfLastListElement = getIndexOfLastListElement(allContacts);

  if (isLoadingCrmAssistants) {
    return (
      <div className={styles.loading}>
        <SkeletonWithWrapper circle height={64} width={64} />
        <SkeletonWithWrapper height={64} width={200} />
      </div>
    );
  }

  if (isError) {
    return <Navigate to={NOT_FOUND} />;
  }

  return (
    <>
      <div className={styles.wrap}>
        <div className={styles.headerContainer}>
          <h2 className={styles.header}>{t('page.crmAssistants.currentAssistants')}</h2>
        </div>
        
        {crmAssistants.length === 0 && (
          <p>{t('page.crmAssistants.currentAssistantsEmpty')}</p>
        )}
        {crmAssistants.length > 0 && (
          <>
            <ul className={styles.assistants}>
              {crmAssistants.map((user) => (
                <li key={user.id}>
                  <AssistantCard assistant={user} isCurrent={true} />
                </li>
              ))}
            </ul>
          </>
        )}
        
        {isOwner && (
          <CsvUploader 
            description={t('page.crmAssistants.uploadShortDescription')}
            onUpload={handleUploadCsv}
            isUploading={isUploading}
            selectButtonText={t('page.crmAssistants.selectFile')}
            uploadButtonText={t('page.crmAssistants.uploadFilters')}
            className={styles.uploadSection}
          />
        )}
      </div>

      <h2 className={styles.header}>{t('page.crmAssistants.otherContacts')}</h2>
      <Input
        name="search"
        onChange={changeSelected}
        placeholder={t('page.crmAssistants.search')}
        className={styles.search}
        value={searchInput}
      />
      <InfiniteList
        limit={20}
        loading={isLoading}
        loadingNextPage={isFetchingNextPage}
        loaderItem={<SkeletonWithWrapper height={64} />}
        emptyListPlaceholder={t('page.crmAssistants.contactsNotFound')}
        listClassName={styles.assistants}
      >
        {allContacts.map((contact, index) => (
          <li key={contact.id} ref={index === indexOfLastListElement ? ref : undefined}>
            <AssistantCard assistant={contact} isCurrent={false} />
          </li>
        ))}
      </InfiniteList>
    </>
  );
}

export default CrmAssistants; 