import styles from './Textarea.module.scss';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import CloseIcon from 'components/icons/Close';
import AudioRecorder from '../AudioRecorder';

export interface TextareaProps {
  name?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  withAudioRecorder?: boolean;
  maxHeight?: number;
}

export function Textarea({ name, onChange, value, className, withAudioRecorder, maxHeight }: TextareaProps): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const emptyIconDivRef = useRef<HTMLDivElement>(null);
  const [isAudioRecording, setIsAudioRecording] = useState(false);
  const maxInputHeight = maxHeight || 100;
  const changeMessage = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange(e.target.value);
  };
  const autoResize = () => {
    const textarea = textareaRef?.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight + 2, maxInputHeight)}px`;
    }
  };

  const emptyTextarea = () => {
    const event = { target: { value: '' } } as React.ChangeEvent<HTMLTextAreaElement>;
    changeMessage(event);
    resetTextareaHeight();
  };

  const resetTextareaHeight = () => {
    if (!textareaRef) {return}
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
    }
  };

  useEffect(() => {
    autoResize();
  }, [value]);

  return (
    <div className={styles.textareaWrapper}>
      <textarea
        ref={textareaRef}
        name={name ?? 'send-message'}
        className={cn(styles.textarea, className && className)}
        value={value}
        onChange={changeMessage}
        rows={1}
      />
      {value !== '' && (
        <div className={styles.emptyTextarea} onClick={emptyTextarea} ref={emptyIconDivRef}>
          <CloseIcon />
        </div>
      )}
      {value === '' && withAudioRecorder && (
        <AudioRecorder
          className={styles.audioRecorder}
          buttonClassName={styles.buttonAudio}
          setInput={onChange}
          setAudioRecording={setIsAudioRecording}
          isFlowBlocked={isAudioRecording}
        />
      )}
    </div>

  )
}
