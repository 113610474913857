import styles from './AssistantCard.module.scss';

import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import cn from 'classnames';
import Icon from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconList';
import { UserCard } from 'entities1/user';
import type { UserDto } from 'dtos';
import {
  useAddCrmAssistantMutation,
  useDeleteCrmAssistantMutation,
} from 'services/crm-assistants';

export type AssistantCardProps = {
  assistant: UserDto;
  isCurrent: boolean;
  onClick?: () => void;
};

function AssistantCard({ assistant, isCurrent, onClick }: AssistantCardProps): JSX.Element {
  const assistantId: UserDto['id'] = assistant.id;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addCrmAssistant, { isLoading: isLoadingAddAssistant }] =
    useAddCrmAssistantMutation();
  const [deleteCrmAssistant, { isLoading: isLoadingDeleteAssistant }] =
    useDeleteCrmAssistantMutation();
  const query = useQueryClient();

  const deleteAssistantHandler = async (): Promise<void> => {
    if (onClick) {
      onClick();
      return;
    }
    
    toast.promise(deleteCrmAssistant(assistantId).unwrap(), {
      loading: t('status.deleting'),
      success: () => {
        // Перезапрашиваем список ассистентов
        query.refetchQueries({ queryKey: ['CrmAssistants'] });
        query.refetchQueries({ queryKey: ['ContactsWithSearch'] });
        return <b>{t('status.successDeletedAssistant')}</b>;
      },
      error: (error) => {
        const errorMessage =
          error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
        return <b>{errorMessage}</b>;
      },
    });
  };

  const addAssistantHandler = () => {
    if (onClick) {
      onClick();
      return;
    }
    
    toast.promise(addCrmAssistant(assistantId).unwrap(), {
      loading: t('status.adding'),
      success: () => {
        // Перезапрашиваем список ассистентов
        query.refetchQueries({ queryKey: ['CrmAssistants'] });
        query.refetchQueries({ queryKey: ['ContactsWithSearch'] });
        return <b>{t('status.success')}</b>;
      },
      error: (error) => {
        const errorMessage =
          error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
        return <b>{errorMessage}</b>;
      },
    });
  };

  const handleCardClick = (e: React.MouseEvent) => {
    // Если это текущий ассистент, переходим на страницу настроек
    if (isCurrent) {
      e.stopPropagation();
      navigate(`/crm/assistants/${assistantId}/settings`);
    } else {
      addAssistantHandler();
    }
  };

  return (
    <div
      className={styles.assistant}
      onClick={handleCardClick}
    >
      <UserCard user={assistant} />

      {isCurrent && !isLoadingDeleteAssistant && (
        <div 
          className={cn(styles.iconContainer, styles.removeAssistantIconContainer)}
          onClick={(e) => {
            e.stopPropagation();
            deleteAssistantHandler();
          }}
        >
          <Icon
            name={IconName.Close2}
            className={styles.removeAssistantIcon}
            width={10}
            height={10}
            viewBox="0 0 10 10"
          />
        </div>
      )}

      {!isCurrent && !isLoadingAddAssistant && (
        <div className={cn(styles.iconContainer, styles.addAssistantIconContainer)}>
          <Icon
            name={IconName.BigPlus}
            className={styles.addAssistantIcon}
            width={11}
            height={11}
            viewBox="0 0 11 11"
          />
        </div>
      )}
    </div>
  );
}

export default AssistantCard; 