import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

import { Layout } from 'components/Layout/Layout';
import { CRMASSISTANTS } from 'pages/paths';
import { useGetCrmAssistantsQuery, useUploadFilterCombinationsMutation } from 'services/crm-assistants';
import styles from './CrmAssistantSettings.module.scss';
import { UserCard } from 'entities1/user';
import { CsvUploader } from 'components/CsvUploader';

function CrmAssistantSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const assistantId = id ? parseInt(id) : undefined;
  const [uploadFilterCombinations, { isLoading: isUploading }] = useUploadFilterCombinationsMutation();
  
  const {
    data: crmAssistants = [],
    isLoading: isLoadingCrmAssistants,
    isError,
  } = useGetCrmAssistantsQuery();

  const assistant = crmAssistants.find(assistant => assistant.id === assistantId);

  useEffect(() => {
    // Если ассистент не найден и данные уже загружены, перенаправляем на страницу ассистентов
    if (!isLoadingCrmAssistants && !assistant) {
      navigate(CRMASSISTANTS);
    }
  }, [assistant, isLoadingCrmAssistants, navigate]);

  const handleUploadCsv = async (file: File) => {
    if (!file || !assistantId) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      await toast.promise(uploadFilterCombinations({ formData, userId: assistantId }).unwrap(), {
        loading: t('status.uploading'),
        success: () => <b>{t('page.crmAssistantSettings.uploadSuccess')}</b>,
        error: (error) => {
          const errorMessage =
            error?.error || error?.data?.message || error?.errorMessage || t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    } catch (error) {
      console.error('Failed to upload filter combinations:', error);
    }
  };

  if (isLoadingCrmAssistants || !assistant) {
    return (
      <Layout>
        <Layout.TopBar>
          <div className={styles.wrap}>
            <Layout.Back to={CRMASSISTANTS} />
          </div>
          <Layout.Title>
            {t('page.crmAssistantSettings.title')}
          </Layout.Title>
        </Layout.TopBar>
        <Layout.Content>
          <div className={styles.loading}>{t('status.loading')}</div>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Layout>
      <Layout.TopBar>
        <div className={styles.wrap}>
          <Layout.Back to={CRMASSISTANTS} />
        </div>
        <Layout.Title>
          {t('page.crmAssistantSettings.title')}
        </Layout.Title>
      </Layout.TopBar>
      <Layout.Content unsetBottomPadding unsetTopPadding>
        <div className={styles.container}>
          <div className={styles.assistantInfo}>
            <UserCard user={assistant} />
          </div>
          
          <div className={styles.fileUploadSection}>
            <CsvUploader 
              title={t('page.crmAssistantSettings.filterSettings')}
              description={t('page.crmAssistantSettings.filterDescription')}
              onUpload={handleUploadCsv}
              isUploading={isUploading}
              selectButtonText={t('page.crmAssistantSettings.selectFile')}
              uploadButtonText={t('page.crmAssistantSettings.uploadButton')}
            />
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
}

export default CrmAssistantSettingsPage; 