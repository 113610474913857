import styles from './InfiniteCrmListV2.module.scss';

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import { InfiniteList } from 'components/InfiniteList';
import CrmContactCard from 'components/CrmContactCard';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';

import { useGetInfiniteCrmV2 } from './api/useGetInfiniteCrmV2';

export type InfiniteCrmListV2Props = {
  tabIndex: number;
}

export function InfiniteCrmListV2({ tabIndex }: InfiniteCrmListV2Props): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize');
  const pageSizeNum = !isNaN(Number(pageSize)) ? Number(pageSize) : undefined;

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfiniteCrmV2(pageSizeNum);

  const contacts = useMemo(() => {
    const allContacts = data?.pages.map((p) => p.content) || [];
    return allContacts.flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    queryClient.removeQueries(['crm-v2'], { exact: false });
  }, []);

  useEffect(() => {
    localStorage.setItem('crmTabIndex', tabIndex.toString());
  }, [tabIndex]);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.contacts}
      loaderItem={<SkeletonWithWrapper height={64} />}
      emptyListPlaceholder={t('page.crm.emptyTab')}
    >
      {contacts.map((c, idx) => (
        <li key={c.id} ref={indexOfLastListElement === idx ? ref : undefined}>
            <CrmContactCard contact={c} />
        </li>
      ))}
    </InfiniteList>
  );
}

export default InfiniteCrmListV2; 