import styles from './index.module.scss';

import cn from 'classnames';
import mime from 'mime-db';

import IconDocument from 'components/icons/Document';

import { MessageDto } from 'dtos';

import { convertBytes } from 'utils';
import { useState } from 'react';
import TextIcon from '../icons/Text';

type Props = {
  message: MessageDto;
  className?: string;
};

const urlReg = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
  'ig'
);
function replaceUrl(str: string) {
  const urlInfo = [...str.matchAll(urlReg)];
  if (urlInfo.length === 0) {
    return str;
  }

  urlInfo.forEach(([u]) => {
    str = str.replace(
      u,
      `<a href="${u}" target="_blank" style="text-decoration: underline;">${u}</a>`
    );
  });

  return str;
}

function Message(props: Props) {
  const {
    timestamp,
    stringRepresentation = '&nbsp;',
    fromMe,
    messageType,
    attachmentUrl,
    attachmentMetadata = {},
  } = props.message;
  const time = new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const { width, height } = attachmentMetadata;

  const text = replaceUrl(stringRepresentation || '');
  const [showTranscription, setShowTranscription] = useState(false);

  let content;

  switch (messageType) {
    case 'IMAGE': {
      if (attachmentUrl) {
        content = (
          <div className={styles.docMessage}>
            <div style={{ aspectRatio: `${width} / ${height}` }}>
              <img className={styles.image} src={attachmentUrl} />
            </div>
            {attachmentMetadata['caption'] && (
              <div className={styles.docCaption}>
                {attachmentMetadata['caption']}
              </div>
            )}
          </div>
        );
      }

      break;
    }
    case 'VIDEO': {
      content =
        (
          <div className={styles.videoMessage}>
            {attachmentUrl && (
              <div style={{ aspectRatio: `${width} / ${height}` }}>
                <video className={styles.video} src={attachmentUrl} controls={true} playsInline={true}/>
              </div>
            )}
            {attachmentMetadata['caption'] && (
              <div className={styles.docCaption}>
                {attachmentMetadata['caption']}
              </div>
            )}
          </div>
        );
      break;
    }
    case 'LOCATION': {
      content = (
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: attachmentUrl ? replaceUrl(attachmentUrl) : text }}
        ></div>
      );
      break;
    }
    case 'DOCUMENT': {
      const extensions = attachmentMetadata.mimeType ? mime[attachmentMetadata.mimeType]?.extensions : null;
      const extension = Array.isArray(extensions) ? extensions[0] : '';
      content = (
        <div className={styles.docMessage}>
          <a href={attachmentUrl} target="_blank" className={styles.docLink}>
            <div className={styles.doc}>
              <IconDocument className={styles.iconDocument} />
              <div className={styles.docLinkTextWrap}>
                {attachmentMetadata.title && (
                  <div className={cn(styles.text, styles.docLinkText)}>
                    {attachmentMetadata.title}{extension ? `.${extension}` : ''}
                  </div>
                )}
                {attachmentMetadata.size && (
                  <div className={styles.docLinkSize}>
                    {convertBytes(Number(attachmentMetadata.size))}
                  </div>
                )}
              </div>
            </div>
          </a>
          {attachmentMetadata['caption'] && (
            <div className={styles.docCaption}>
              {attachmentMetadata['caption']}
            </div>
          )}
        </div>
      );
      break;
    }
    case 'AUDIO': {
      var transcription = attachmentMetadata['transcription'];
      content = attachmentUrl ? (
        <div className={styles.audioWrapper}>
          <div className={styles.audioPlayerWrapper}>
            <audio controls className={styles.audioPlayer}>
              <source src={attachmentUrl} type={attachmentMetadata.mimeType} />
              Browser does not support audio.
            </audio>
            {transcription && (
              <div className={styles.transcriptionIconContainer} onClick={() => setShowTranscription(!showTranscription)}>
                <TextIcon className={styles.transcriptionIcon} />
              </div>
            )}
          </div>
          {transcription && (
            <div className={cn(styles.docCaption, !showTranscription && styles.hide)}>
              {transcription}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>
      );
      break;
    }
    case 'TEXT': {
      content = <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>;
      break;
    }
    case 'CNT_COMMENT': {
      content = (
        <>
          <div className={cn(styles.text, styles.concave)} dangerouslySetInnerHTML={{ __html: text }}></div>
        </>
      );
      break;
    }
    default: {
      content = <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }}></div>;
      break;
    }
  }

  return (
    <li className={cn(styles.message, {
      [styles.me]: fromMe,
      [styles.imageWrapper]: messageType === 'IMAGE',
      [styles.cntComment]: messageType === 'CNT_COMMENT',
    })}>
      {content}
      <div className={cn(styles.time, { [styles.blackTime]: messageType === 'CNT_COMMENT' })}>{time}</div>
    </li>
  );
}

export default Message;
