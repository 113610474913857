import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../fetchBaseQueryWithAuth';
import { FriendsParams } from 'api/balances/types';
import {
  WAContactExtendedDto,
  PageWAContactDto,
  WAContactDto,
  PageWASimplifiedContactDto,
  BusinessType,
  Readiness, ResponseUrgency, FunnelStatus,
} from 'dtos';

type PageParams = {
  page?: number;
  limit?: number;
};

type MessageParams = {
  contactId: number;
  formData: FormData;
};

type ContactListParams = {
  businessType?: BusinessType[];
  readiness?: Readiness[];
  responseUrgency?: ResponseUrgency[];
  funnelStatus?: FunnelStatus[];
  page?: number;
  limit?: number;
};

export const INITIAL_PAGE: FriendsParams['page'] = 1;
export const INITIAL_LIMIT: FriendsParams['limit'] = 20;

export const crmApi = createApi({
  reducerPath: 'crmApi',
  baseQuery: baseQueryWithReauth(true),
  tagTypes: ['CRM'],
  endpoints: (builder) => ({
    getCrmContacts: builder.query<PageWAContactDto, ContactListParams>({
      query: (params) => ({
        url: `/crm/contacts`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map((contact: WAContactDto) => ({ type: 'CRM' as const, id: contact.id })), 'CRM']
          : ['CRM'],
    }),

    getCrmContactsV2: builder.query<PageWAContactDto, PageParams>({
      query: (params) => ({
        url: `/crm/v2/contacts`,
        params,
      }),
      providesTags: (result) =>
        result?.content
          ? [...result.content.map((contact: WAContactDto) => ({ type: 'CRM' as const, id: contact.id })), 'CRM']
          : ['CRM'],
    }),

    getCrmContactById: builder.query<WAContactExtendedDto, number>({
      query: (contactId) => ({
        url: `/crm/contacts/${contactId}`,
      }),
      providesTags: (_result, _error, contactId) => [{ type: 'CRM', id: contactId }],
    }),

    switchCrmContactById: builder.mutation<void, number>({
      query: (contactId) => ({
        url: `/crm/contacts/ignore`,
        params: {contactId},
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, contactId) => [{ type: 'CRM', id: contactId }],
    }),

    sendMessage: builder.mutation<void, MessageParams>({
      query: (messageParams) => ({
        url: `/crm/send-message`,
        params: { contactId: messageParams.contactId },
        body: messageParams.formData,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, {contactId}) => [{ type: 'CRM', id: contactId }],
    }),

    getNextUser: builder.query<number | null, { currentContactId: number, filters?: FunnelStatus[] }>({
      query: (params) => ({
        url: '/crm/contacts/next',
        params: {
          currentContactId: params.currentContactId,
          filters: params.filters
        },
      }),
    }),

    reanalyzeChat: builder.mutation<void, number>({
      query: (contactId) => ({
        url: `/crm/contacts/analyze-chat`,
        params: { contactId },
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, contactId) => [{ type: 'CRM', id: contactId }],
    }),

    searchContacts: builder.query<PageWASimplifiedContactDto, { search?: string, page: number, limit: number }>({
      query: (params) => ({
        url: '/crm/contacts/search',
        params,
      }),
    }),

    getOrCreateContact: builder.mutation<WAContactDto, string>({
      query: (phoneNumber) => ({
        url: '/crm/contact',
        params: { phoneNumber },
        method: 'POST',
      }),
      invalidatesTags: (result, _error, phoneNumber) => [{ type: 'CRM', id: result?.id }],
    }),

    addComment: builder.mutation<WAContactDto, { contactId: number, comment: string }>({
      query: (params) => ({
        url: '/crm/contact/comment',
        params: { contactId: params.contactId },
        body: params.comment,
        method: 'POST',
      }),
      invalidatesTags: (_result, _error, params) => [{ type: 'CRM', id: params.contactId }],
    }),

  }),
});

export const {
  useLazyGetCrmContactsQuery,
  useLazyGetCrmContactsV2Query,
  useGetCrmContactByIdQuery,
  useSwitchCrmContactByIdMutation,
  useSendMessageMutation,
  useLazyGetNextUserQuery,
  useReanalyzeChatMutation,
  useLazySearchContactsQuery,
  useGetOrCreateContactMutation,
  useAddCommentMutation
} = crmApi;
