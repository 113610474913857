import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import RefreshIcon from 'components/icons/Refresh';
import AssistantsIcon from 'components/icons/Assistants';
import { Layout } from 'components/Layout/Layout';
import { CRMASSISTANTS } from 'pages/paths';
import { useIsUserCrmOwnerQuery } from 'services/crm-assistants';

export function CrmHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: isOwner } = useIsUserCrmOwnerQuery();

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleAssistantsClick = () => {
    navigate(CRMASSISTANTS);
  };

  return (
    <Layout.TopBar unsetRowGap>
      <Layout.Title className={styles.title}>
        {t('page.crm.title')}
        <div className={styles.icons}>
          <div onClick={handleRefreshClick} className={styles.icon}>
            <RefreshIcon />
          </div>
          {isOwner && (
            <div onClick={handleAssistantsClick} className={styles.icon}>
              <AssistantsIcon />
            </div>
          )}
        </div>
      </Layout.Title>
    </Layout.TopBar>
  );
}
