import styles from './Assistants.module.scss';

import { Fragment, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import AssistantCard from 'components/Assistants/AssistantCard';

import {
  useDeleteAssistantMutation,
  useDeleteBalanceAssistantMutation,
  useGetAllBalanceAssistantsQuery,
  useGetAssistantsQuery,
} from 'services/assistants';
import { useGetCrmAssistantsQuery, useDeleteCrmAssistantMutation } from 'services/crm-assistants';
import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import BalanceCard from 'components/BalanceCard';

function Assistants(): JSX.Element {
  const { t } = useTranslation();

  const { data: mainAssistants = [], isLoading: isLoadingAllAssistant } =
    useGetAssistantsQuery();
  const { data: allAssistantsInfo = [] } = useGetAllBalanceAssistantsQuery();
  const { data: crmAssistants = [], isLoading: isLoadingCrmAssistants } = useGetCrmAssistantsQuery();
  const [deleteAssitant] = useDeleteAssistantMutation();
  const [deleteBalanceAssitant] = useDeleteBalanceAssistantMutation();
  const [deleteCrmAssistant] = useDeleteCrmAssistantMutation();

  const deleteAssistantHandle = useCallback(
    (assistantId: number) => () => {
      toast.promise(deleteAssitant(assistantId).unwrap(), {
        loading: t('status.deleting'),
        success: () => <b>{t('status.successDeletedAssistant')}</b>,
        error: (error) => {
          const errorMessage =
            error?.error ||
            error?.data?.message ||
            error?.errorMessage ||
            t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    },
    [deleteAssitant, t]
  );

  const deleteBalanceAssistantHandle = useCallback(
    ({ balanceId, assistantId }: { balanceId: number; assistantId: number }) =>
      () => {
        toast.promise(
          deleteBalanceAssitant({ balanceId, assistantId }).unwrap(),
          {
            loading: t('status.deleting'),
            success: () => <b>{t('status.successDeletedAssistant')}</b>,
            error: (error) => {
              const errorMessage =
                error?.error ||
                error?.data?.message ||
                error?.errorMessage ||
                t('status.smthWrong');
              return <b>{errorMessage}</b>;
            },
          }
        );
      },
    [deleteBalanceAssitant, t]
  );

  const deleteCrmAssistantHandle = useCallback(
    (assistantId: number) => () => {
      toast.promise(deleteCrmAssistant(assistantId).unwrap(), {
        loading: t('status.deleting'),
        success: () => <b>{t('status.successDeletedAssistant')}</b>,
        error: (error) => {
          const errorMessage =
            error?.error ||
            error?.data?.message ||
            error?.errorMessage ||
            t('status.smthWrong');
          return <b>{errorMessage}</b>;
        },
      });
    },
    [deleteCrmAssistant, t]
  );

  if (isLoadingAllAssistant || isLoadingCrmAssistants) {
    return (
      <>
        <h2 className={styles.header}>{t('page.assistants.mainAssistants')}</h2>
        <div className={styles.loading}>
          <SkeletonWithWrapper circle height={64} width={64} />
          <SkeletonWithWrapper height={64} width={200} />
        </div>
      </>
    );
  }

  if (mainAssistants.length === 0 && allAssistantsInfo.length === 0 && crmAssistants.length === 0) {
    return (
      <div className={styles.empty}>
        <p>{t('component.assistants.empty')}</p>
      </div>
    );
  }

  return (
    <>
      <h2 className={styles.header}>{t('page.assistants.mainAssistants')}</h2>
      {mainAssistants.length > 0 && (
        <ul className={styles.assistants}>
          {mainAssistants.map((a) => (
            <li key={a.id}>
              <AssistantCard
                assistant={a}
                onClick={deleteAssistantHandle(a.id)}
              />
            </li>
          ))}
        </ul>
      )}
      {mainAssistants.length === 0 && (
        <p className={styles.assistants}>{t('page.assistants.noAssistants')}</p>
      )}

      {crmAssistants.length > 0 && (
        <>
          <h2 className={styles.header}>{t('page.assistants.crmAssistants')}</h2>
          <ul className={styles.assistants}>
            {crmAssistants.map((a) => (
              <li key={a.id}>
                <AssistantCard
                  assistant={a}
                  onClick={deleteCrmAssistantHandle(a.id)}
                />
              </li>
            ))}
          </ul>
        </>
      )}

      {allAssistantsInfo.map(({ assistants, balanceInfo }) => (
        <Fragment key={balanceInfo.id}>
          <div className={styles.balance}>
            <BalanceCard
              balance={balanceInfo}
              titleClassName={styles.balanceTitle}
            />
          </div>
          <ul className={styles.assistants}>
            {assistants.map(({ user }) => (
              <li key={user.id}>
                <AssistantCard
                  assistant={user}
                  onClick={deleteBalanceAssistantHandle({
                    balanceId: balanceInfo.id,
                    assistantId: user.id,
                  })}
                />
              </li>
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  );
}

export default Assistants;
