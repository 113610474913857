import styles from './CrmContactPage.module.scss';

import React from 'react';
import RefreshIcon from 'components/icons/Refresh';
import { Layout } from 'components/Layout/Layout';
import CrmContact from 'components/CrmContact';
import { useReanalyzeChatMutation } from 'services/crm';
import { useParams } from 'react-router-dom';
import { CRM } from 'pages/paths';

function CrmContactPage(): JSX.Element {
  const [reanalyzeChatMutation] = useReanalyzeChatMutation();
  const { id } = useParams();
  const crmContactId: number = Number(id);
  const handleReanalyzeClick = () => {
    reanalyzeChatMutation(crmContactId)
  };

  return (
    <Layout hasMenu={false}>
      <Layout.TopBar className={styles.header}>
        <div className={styles.wrap}>
          <Layout.Back to={CRM}/>
          <div onClick={handleReanalyzeClick}>
            <RefreshIcon />
          </div>
        </div>
      </Layout.TopBar>
      <Layout.Content className={styles.layout} unsetTopPadding unsetBottomMargin unsetBottomPadding>
        <CrmContact />
      </Layout.Content>
    </Layout>
  );
}

export default CrmContactPage;
