import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import styles from './AudioRecorder.module.scss';
import cn from 'classnames';
import StopIcon from '../icons/Stop';
import MicrophoneIcon from '../icons/Microphone';
import CloseIcon from '../icons/CloseBold';
import { convertBlobToWav } from 'utils/convertBlobToWav';
import { useTranscribeAudioMutation } from 'services/openai';
import Spinner from '../Spinner/Spinner';

type AudioRecorderProps = {
  setInput: (str: string) => void;
  className?: string;
  buttonClassName?: string;
  isFlowBlocked?: boolean;
  setAudioRecording: (b: boolean) => void;
};

const AudioRecorder: React.FC<AudioRecorderProps> = ({ setInput, setAudioRecording, className, isFlowBlocked, buttonClassName }) => {
  const [audioUrl, setAudioUrl] = useState<string | undefined>();
  const [isRecording, setIsRecording] = useState(false);
  const [audioMutation, { isLoading }] = useTranscribeAudioMutation();
  const ReactMediaRecorder = typeof window !== "undefined"
    ? require('react-media-recorder').ReactMediaRecorder
    : null;

  useEffect(() => {
    setAudioRecording(isLoading);
  }, [isLoading]);

  const handleStop = async (blobUrl: string, blob: Blob) => {
    if (typeof window !== 'undefined' && typeof AudioContext !== 'undefined') {
      try {
        const wavBlob = await convertBlobToWav(blob);
        if (wavBlob) {
          const audioFile = new File([wavBlob], 'audio.wav', { type: wavBlob.type });
          await audioMutation({ audio: audioFile }).unwrap()
            .then((response) => setInput(response));
          setAudioUrl(undefined);
        }
      } catch (error) {
        console.error('Error during audio transcription', error);
      }
    } else {
      console.error('AudioContext is not available in this environment');
    }
  };

  const handleResetAudio = () => {
    if (audioUrl) {
      setAudioUrl(undefined);
    }
  };
  const buttonStyles = buttonClassName ? buttonClassName : styles.button;
  if (!ReactMediaRecorder) return null;

  return (
    <div className={cn(className, styles.audioRecorder)}>
      <ReactMediaRecorder
        audio
        onStop={handleStop}
        // @ts-ignore
        render={({ startRecording, stopRecording }) => (
          <div className={styles.recorderControls}>
            {!isRecording && !audioUrl ? (
              <Button
                className={cn(buttonStyles, styles.startRecordButton, isLoading && styles.loadingButton)}
                onClick={() => {
                  startRecording();
                  setIsRecording(true);
                }}
                disabled={isFlowBlocked}
              >
                {isLoading ? (
                  <Spinner className={styles.spinner} />
                ) : (
                  <MicrophoneIcon className={cn(styles.buttonIcon, isLoading && styles.loadingIcon)} />
                )}
              </Button>
            ) : !isRecording && audioUrl ? (
              <Button
                className={cn(buttonStyles, styles.resetButton)}
                onClick={handleResetAudio}
                disabled={isFlowBlocked}
              >
                <CloseIcon className={styles.buttonIcon} />
              </Button>
            ) : (
              <Button
                className={cn(buttonStyles, styles.stopRecordButton)}
                onClick={() => {
                  stopRecording();
                  setIsRecording(false);
                }}
                disabled={isFlowBlocked}
              >
                <StopIcon className={styles.buttonIcon} />
              </Button>
            )}
          </div>
        )}
      />
    </div>
  );
};

export default AudioRecorder;
