import { useInfiniteQuery } from '@tanstack/react-query';
import { INITIAL_LIMIT, INITIAL_PAGE, useLazyGetCrmContactsQuery } from 'services/crm';
import { BusinessType, FunnelStatus, Readiness, ResponseUrgency } from 'dtos';

export const useGetInfiniteCrm = (
  businessType: BusinessType[] | undefined,
  readiness: Readiness[] | undefined,
  responseUrgency: ResponseUrgency[] | undefined,
  funnelStatus: FunnelStatus[] | undefined,
  pageSize: number | undefined
) => {
  const [getCrmContacts] = useLazyGetCrmContactsQuery();

  return useInfiniteQuery({
    queryKey: ['crm'],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getCrmContacts({
        businessType: businessType,
        readiness: readiness,
        responseUrgency: responseUrgency,
        funnelStatus: funnelStatus,
        page: pageParam,
        limit: pageSize || INITIAL_LIMIT,
      }).unwrap() as any;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    keepPreviousData: true,
    refetchInterval: 60000,
  });
};
