import { BusinessType, Readiness, ResponseUrgency } from 'dtos';
import styles from './LidEmodji.module.scss';
import cn from 'classnames';

interface LeadEmojiProps {
  responseUrgency: ResponseUrgency;
  readiness: Readiness;
  isAnalyzing: boolean;
  businessType: BusinessType;
  followUp: boolean;
}

const LeadEmoji = ({ responseUrgency, readiness, isAnalyzing, businessType, followUp }: LeadEmojiProps) => {
  const responseUrgencyEmoji = (() => {
    switch (responseUrgency) {
      case ResponseUrgency.High:
        return '🔥';
      case ResponseUrgency.VeryHigh:
        return '💥';
      case ResponseUrgency.Normal:
        return '🎾'
      default:
        return '';
    }
  })();
  const readinessEmoji = (() => {
    switch (readiness) {
      case Readiness.Ready:
        return '💵';
      case Readiness.AlmostDone:
        return '🏁';
      default:
        return '';
    }
  })();

  const businessTypeEmoji = (() => {
    switch (businessType) {
      case BusinessType.CurrencyExchange:
        return '💱';
      case BusinessType.CarSales:
        return '📐';
      case BusinessType.CarRental:
        return '🏎';
      case BusinessType.SparePartsAndService:
        return '🛠';
      case BusinessType.Other:
        return '🌐'
      default:
        return '';
    }
  })();

  const followUpEmoji = followUp ? '🕘' : ''

  return (
    <div className={styles.emoji}>
      {isAnalyzing && (
        <div className={styles.isAnalyzing}>
          ⌛
        </div>
      )}
      {followUp && (
        <div className={cn(styles.responseUrgency, isAnalyzing && styles.opacity)}>
          {followUpEmoji}
        </div>
      )}
      {responseUrgencyEmoji != '' && !isAnalyzing && (
        <div className={cn(styles.responseUrgency, isAnalyzing && styles.opacity)}>
          {responseUrgencyEmoji}
        </div>
      )}
      {readinessEmoji != '' && (
        <div className={cn(styles.readiness, isAnalyzing && styles.opacity)}>
          {readinessEmoji}
        </div>
      )}
      {businessTypeEmoji != '' && (
        <div className={cn(styles.businessType, isAnalyzing && styles.opacity)}>
          {businessTypeEmoji}
        </div>
      )}
    </div>
  )
}

export default LeadEmoji;
