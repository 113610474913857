import { configureStore } from '@reduxjs/toolkit'
import * as Sentry from "@sentry/react";
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { balancesApi } from 'services/balances';
import { usersApi } from 'services/users';
import { contactsApi } from 'services/contacts';
import { crmApi } from 'services/crm';
import { assistantsApi } from 'services/assistants';
import { crmAssistantsApi } from 'services/crm-assistants';
import { authApi } from 'services/auth';
import { modalSlice } from 'slices/modal';
import { pushSubscriptionsApi } from 'services/subscriptions';
import { paymentsApi } from 'services/payments';
import { callbackApi } from 'services/callback';
import { tasksApi } from 'services/tasks';
import { exchangeApi } from 'services/exchange';
import { notificationsApi } from 'services/notifications';
import { openaiApi } from '../../services/openai';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [balancesApi.reducerPath]: balancesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [crmApi.reducerPath]: crmApi.reducer,
    [assistantsApi.reducerPath]: assistantsApi.reducer,
    [crmAssistantsApi.reducerPath]: crmAssistantsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [pushSubscriptionsApi.reducerPath]: pushSubscriptionsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [callbackApi.reducerPath]: callbackApi.reducer,
    [tasksApi.reducerPath]: tasksApi.reducer,
    [openaiApi.reducerPath]: openaiApi.reducer,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    // slices
    [modalSlice.name]: modalSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      balancesApi.middleware,
      usersApi.middleware,
      contactsApi.middleware,
      crmApi.middleware,
      assistantsApi.middleware,
      crmAssistantsApi.middleware,
      authApi.middleware,
      pushSubscriptionsApi.middleware,
      notificationsApi.middleware,
      paymentsApi.middleware,
      tasksApi.middleware,
      openaiApi.middleware,
      exchangeApi.middleware,
      callbackApi.middleware,
    ]),
  enhancers: (existingEnhancers) => {
    return existingEnhancers.concat(sentryReduxEnhancer);
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
