import { useInfiniteQuery } from '@tanstack/react-query';
import { useLazySearchContactsQuery } from 'services/crm';
import { FriendsParams } from '../../../api/balances/types';


const INITIAL_PAGE: FriendsParams['page'] = 1;
const INITIAL_LIMIT: FriendsParams['limit'] = 20;

export const useGetInfiniteCrmContactsSearch= (search?: string) =>  {
  const [getCrmContacts] = useLazySearchContactsQuery();

  return useInfiniteQuery({
    queryKey: ['crmSearch', search],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getCrmContacts({
        page: pageParam,
        limit: INITIAL_LIMIT,
        search
      }).unwrap() as any;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    enabled: search !== undefined && search !== '',
    keepPreviousData: true,
  });
};
