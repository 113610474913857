import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import styles from './CsvUploader.module.scss';

export interface CsvUploaderProps {
  title?: string;
  description: string; 
  onUpload: (file: File) => void;
  isUploading?: boolean;
  className?: string;
  selectButtonText?: string;
  uploadButtonText?: string;
  loadingText?: string;
  resetAfterUpload?: boolean;
}

function CsvUploader({
  title,
  description,
  onUpload,
  isUploading = false,
  className,
  selectButtonText,
  uploadButtonText,
  loadingText,
  resetAfterUpload = true
}: CsvUploaderProps): JSX.Element {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previousIsUploading, setPreviousIsUploading] = useState(isUploading);

  // Сбрасываем выбранный файл после успешной загрузки
  useEffect(() => {
    if (resetAfterUpload && previousIsUploading && !isUploading) {
      resetFile();
    }
    setPreviousIsUploading(isUploading);
  }, [isUploading, resetAfterUpload, previousIsUploading]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
    }
  };

  const resetFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={`${styles.container} ${className || ''}`}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <p className={styles.description}>{description}</p>
      
      <div className={styles.fileUpload}>
        <input
          type="file"
          ref={fileInputRef}
          accept=".csv"
          onChange={handleFileChange}
          className={styles.fileInput}
        />
        <div className={styles.fileControls}>
          <Button
            onClick={handleUploadButtonClick}
            className={styles.selectButton}
          >
            {selectedFile 
              ? (selectedFile.name.length > 20 
                  ? selectedFile.name.slice(0, 17) + '...' 
                  : selectedFile.name) 
              : (selectButtonText || t('component.csvUploader.selectFile'))}
          </Button>
          
          {selectedFile && (
            <Button
              onClick={handleUpload}
              disabled={isUploading}
              className={styles.uploadButton}
            >
              {isUploading 
                ? (loadingText || t('status.uploading')) 
                : (uploadButtonText || t('component.csvUploader.uploadButton'))}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CsvUploader; 