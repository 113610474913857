import { useInView } from 'react-intersection-observer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useGetInfiniteCrmContactsSearch } from 'modules/InfiniteCrmContactListSearch/api/useGetInfiniteCrm';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import cn from 'classnames';
import styles from './SecondCrmInput.module.scss';
import { InfiniteList } from 'components/InfiniteList';
import CrmContactCard from 'components/CrmContactCard';
import { debounce, getFunnelStatusesFromString } from 'utils';
import ArrowUpNew from 'components/icons/ArrowUpNew';
import Button from 'components/Button/Button';
import { Textarea } from 'components/Textarea';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddCommentMutation, useGetOrCreateContactMutation, useLazyGetNextUserQuery } from 'services/crm';
import * as Sentry from '@sentry/react';
import { toast } from 'react-hot-toast';
import { WASimplifiedContactDto } from 'dtos';
import { useTranslation } from 'react-i18next';

export function SecondCrmInput(): JSX.Element {
  const { t } = useTranslation();
  const { ref, inView } = useInView();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');
  const [foundContacts, setFoundContacts] = useState<WASimplifiedContactDto[]>([]);
  const [search, setSearch] = useState<string>();
  const [showSearchList, setShowSearchList] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const funnelStatus = localStorage.getItem('funnelStatus');
  const funnelStatusArray = getFunnelStatusesFromString(funnelStatus);
  const { data, fetchNextPage } = useGetInfiniteCrmContactsSearch(search);
  const [getNextUser] = useLazyGetNextUserQuery();
  useEffect(() => {
      const contacts = data?.pages.map((p) => p.content).flat() || [];
      setFoundContacts(contacts.reverse());
  }, [data]);

  useEffect(() => {
    const container = document.getElementById('foundContacts');
    if (container && container.lastElementChild) {
      container.lastElementChild.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }, [foundContacts]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    setValue('');
    setSearch('');
    setShowSearchList(false);
    setFoundContacts([]);
  }, [location.pathname]);

  const [createOrFetchContact, { isLoading }] = useGetOrCreateContactMutation();
  const [addComment] = useAddCommentMutation();

  const debounceSetSearchString = useCallback(
    debounce((searchString: string) => setSearch(searchString), 500),
    [value]
  );

  const resetState = () => {
    setValue('');
    setSearch('');
    setFoundContacts([]);
    setShowSearchList(false);
  }

  const handleSubmit = () => {
    const sanitizedValue = sanitizeString(value)
    if (sanitizedValue.length < 20 && !isNaN(Number(sanitizedValue))) {
        toast.promise(createOrFetchContact(sanitizedValue).unwrap(), {
            loading: <b>{t('status.creating')}</b>,
            success: (contact) => {
              navigate(`/crm/${contact.id}`);
              resetState();
              return null;
            },
            error: (error) => {
              Sentry.captureException(error);
              return <b>{t('status.smthWrong')}</b>;
            },
          });
    } else {
      const split = location.pathname.split('/');
      const contactId = Number(split[split.length - 1]);
      addComment({ contactId: contactId, comment: value }).unwrap()
        .then(() => {
          toast.success(<b>{t('status.success')}</b>);
          getNextUser({ currentContactId: contactId, filters: funnelStatusArray }).unwrap()
            .then((nextUserId) => {
              nextUserId ? navigate(`/crm/${nextUserId}`) : navigate('/crm');
            })
            .catch((e) => {
                Sentry.captureException(e);
                navigate('/crm');
              },
            );
      }).catch((e) => {
        Sentry.captureException(e);
        toast.error(<b>{t('status.smthWrong')}</b>);
      })
    }
  }

  const changeSelected = (value: string) => {
    setValue(value);
    const sanitizedValue = sanitizeString(value);
    const isValidNumber = !isNaN(Number(sanitizedValue));
    if (isValidNumber && sanitizedValue.length > 3) {
      debounceSetSearchString(sanitizedValue);
      setShowSearchList(true);
    }
    if (isValidNumber && sanitizedValue.length <= 3) {
      debounceSetSearchString('');
      setFoundContacts([]);
      setShowSearchList(false);
    }
  };

  return (
    <div className={styles.secondCrmInputBlock}>
      <div className={cn(styles.list, !showSearchList && styles.hide)} ref={listRef} id="foundContacts">
        <InfiniteList listClassName={cn(styles.contacts)}>
          {foundContacts && foundContacts.map((c, idx) => (
            <li key={c.id} ref={getIndexOfLastListElement(foundContacts) === idx ? ref : undefined}>
              <CrmContactCard contact={c} isSimplified />
            </li>
          ))}
        </InfiniteList>
      </div>
      <div className={styles.secondCrmInput}>
        <Textarea
          name="send-message"
          className={styles.textarea}
          value={value}
          onChange={changeSelected}
          withAudioRecorder={true}
        />
        <Button
          onClick={handleSubmit}
          className={styles.button}
          isLoading={isLoading}
          disabled={value?.length === 0 || isLoading}
        >
          <ArrowUpNew className={styles.arrowIcon} />
        </Button>
      </div>
    </div>

  );
}

function sanitizeString(str: string): string {
  return str.trim()
    .replace(/[\u200B-\u200D\uFEFF\u00A0\u202A-\u202E\u0000-\u001F\u007F\u2060\u2061-\u2064\u2028\u2029\uFFFC\uFFFD]/g, '');
}
