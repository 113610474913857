import styles from './CrmContact.module.scss';

import cn from 'classnames';

import React, { type ChangeEvent, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import Message from 'components/Message';
import { formatDate, getFunnelStatusesFromString, groupListByCreatedDate } from 'utils';

import { Avatar, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { useGetCrmContactByIdQuery, useLazyGetNextUserQuery, useSendMessageMutation } from 'services/crm';
import InfoIcon from 'components/icons/Info';
import CloseIcon from 'components/icons/Close';
import LeadEmoji from 'components/LeadEmoji';
import ArrowUpNew from 'components/icons/ArrowUpNew';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import PlusBold from 'components/icons/PlusBold';
import { SecondCrmInput } from 'components/SecondCrmInput';
import { Textarea } from 'components/Textarea';
import * as Sentry from '@sentry/react';
import isEmpty from 'lodash/isEmpty';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

function CrmContact(): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const bottomPanelRef = useRef<HTMLDivElement>(null);
  const messageListRef = useRef<HTMLUListElement>(null);
  const [isHiddenMotivation, setIsHiddenMotivation] = useState(true);
  const { language } = useCurrentUser();

  const crmContactId: number = Number(id);

  const { data, isLoading } = useGetCrmContactByIdQuery(crmContactId, {
    skip: !crmContactId,
    pollingInterval: 60000,
    refetchOnMountOrArgChange: true,
  });
  const [sendMessage, { isLoading: isLoadingSendMessage }] = useSendMessageMutation();
  const [getNextUser] = useLazyGetNextUserQuery();

  const [messageText, setMessageText] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const funnelStatus = localStorage.getItem('funnelStatus');
  const funnelStatusArray = getFunnelStatusesFromString(funnelStatus);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles(Array.from(e.target.files));
    }
  };

  const handleRemoveFile = (fileToRemove: File): void => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleSubmit = () => {
    try {
      const formData = new FormData();
      formData.append('message', messageText || '');
      selectedFiles.forEach((file) => formData.append('attachments', file));
      sendMessage({ contactId: crmContactId, formData: formData }).unwrap()
        .then(() => {
          setMessageText('');
          setSelectedFiles([]);
          toast.success(t('status.sent'));
          getNextUser({ currentContactId: crmContactId, filters: funnelStatusArray }).unwrap()
            .then((nextUserId) => {
              nextUserId ? navigate(`/crm/${nextUserId}`) : navigate('/crm');
            })
            .catch((e) => {
                Sentry.captureException(e);
                navigate('/crm');
              },
            );
        })
        .catch((e) => {
          Sentry.captureException(e, {
            contexts: {
              additionalInfo: {
                message: messageText,
                contactId: crmContactId,
                description: 'Failed to send message',
              }
            }
          });
          toast.error(t('status.smthWrong'));
        });
    } catch (e) {
      Sentry.captureException(e, {
        contexts: {
          additionalInfo: {
            message: messageText,
            contactId: crmContactId,
            description: 'Failed to send message',
          }
        }
      });
      if (!(e instanceof Error)) {
        toast.error(t('status.smthWrong'));
        return;
      }
      toast.error(e.message);
    }
  };

  const crmContact = useMemo(() => {
    if (data == null) {
      return null;
    }

    return {
      ...data,
      waChatLink: `https://wa.me/${data.phoneNumber}`,
    };
  }, [data]);

  const messages = useMemo(() => {
    if (!crmContact) {
      return [];
    }

    const temp = crmContact.messages.map(item => ({ ...item, createdAt: item.timestamp }));

    return groupListByCreatedDate(temp || [], true, true);
  }, [crmContact]);

  // const [switchContact] = useSwitchCrmContactByIdMutation();
  // const handleSwitchClick = async (id: number) => {
  //   await switchContact(id).then(() => {
  //     queryClient.removeQueries(['crm'], { exact: false });
  //     navigate(-1);
  //   });
  // };

  const handleOpenMotivation = () => {
    setIsHiddenMotivation(!isHiddenMotivation);
  }

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    toast.success(t('button.copied'))
  };

  const scrollDown = (behavior: 'auto' | 'instant' | 'smooth' = 'auto') => {
    if (messageListRef.current) {
      const container = messageListRef.current;
      requestAnimationFrame(() => {
        container.scrollTo({ top: container.scrollHeight, behavior });
      });
    }
  };

  useLayoutEffect(() => {
    scrollDown('instant');
  }, [messages, selectedFiles]);

  useLayoutEffect(() => {
    setMessageText('');
    setSelectedFiles([]);
    if (crmContact?.operatorResponse && crmContact.operatorResponse.trim() !== '') {
      setMessageText(crmContact.operatorResponse);
      scrollDown();
    } else if (crmContact?.followupMessage && crmContact.followupMessage.trim() !== '') {
      setMessageText(crmContact.followupMessage);
      scrollDown();
    }
  }, [crmContact?.id]);

  const shouldShowFollowupBadge = (contact: typeof crmContact): boolean => {
    return !!contact && contact.followupMessage !== undefined && contact.operatorResponse === undefined;
  };

  const showFollowupBadge = shouldShowFollowupBadge(crmContact);
  const formattedFollowupTime = crmContact?.followupTime
    ? formatDate(new Date(crmContact.followupTime), undefined, language)
    : t('page.crm.undefinedTime');

  if (isLoading) {
    return <Spinner className={styles.spinner} />;
  }

  if (!crmContact) {
    return null;
  }

  return (
    <div className={styles.friend} ref={ref}>
      <div className={styles.user}>
        <div className={styles.blockUserInfo}>
          <Avatar
            username={crmContact.userName}
            profilePictureUrl={crmContact.profilePictureUrl}
            waChatLink={crmContact.waChatLink}
            size={AvatarSize.Payment}
          />
          <div className={styles.userInfo}>
            <div>{crmContact.userName}</div>
            <div>{crmContact.phoneNumber}</div>
          </div>
        </div>


        <div className={styles.blockStatusInfo}>
          <LeadEmoji
            responseUrgency={crmContact.responseUrgency}
            readiness={crmContact.readiness}
            isAnalyzing={crmContact.isAnalyzing}
            businessType={crmContact.businessType}
            followUp={showFollowupBadge}
          />

          {/*<div className={styles.hideIcon} onClick={() => handleSwitchClick(crmContact.id)}>*/}
          {/*  {crmContact.isIgnored ? <ShowIcon /> : <HideIcon />}*/}
          {/*</div>*/}

          {crmContact.motivations && !isEmpty(crmContact.motivations) &&
            <div onClick={handleOpenMotivation}>
              <InfoIcon />
            </div>
          }
        </div>
      </div>
      <div className={cn(styles.motivations, isHiddenMotivation && styles.hidden)}>
        <div className={styles.closeMotivation} onClick={handleOpenMotivation}>
          <CloseIcon className={styles.closeIcon} />
        </div>
        {crmContact.motivations?.map((m, index) => (
          <div key={index} className={styles.motivation} onClick={() => handleCopy(m.content)}>
            <div className={styles.motivationName}>{m.name}</div>
            <div className={styles.motivationContent}>{m.content}</div>
          </div>
        ))}
      </div>

      <ul className={styles.messageList} id="message-list" ref={messageListRef}>
        {messages.map(m => {
          if (typeof m === 'string') {
            return (
              <h3 key={m} className={styles.date}>
                {m}
              </h3>
            );
          }

          return <Message key={m.id} message={m} />;
        })}
      </ul>
      <div className={styles.bottomPanel} ref={bottomPanelRef}>
        <div className={styles.blockAttachments}>
          <div className={styles.fileInputWrapper}>
            <input
              type="file"
              id="file-upload"
              className={styles.fileInput}
              onChange={handleFileChange}
              multiple
            />
          </div>

          {selectedFiles.length > 0 && (<div className={styles.fileList}>
            {selectedFiles.map((file, index) => (
              <div key={index} className={styles.fileItem}>
                <span className={styles.fileName}>{file.name}</span>
                <div className={styles.fileRemoveButton} onClick={() => handleRemoveFile(file)}>
                  <CloseIcon />
                </div>
              </div>
            ))}
          </div>)}
        </div>
        <div className={styles.inputsBlock}>
          <div className={cn(styles.bottomInputBlock, styles.blockMessage)}>
            <div className={styles.textareaContainer}>
              {showFollowupBadge && (
                <span className={styles.followupBadge}>
                  {t('page.crm.followupBadge') + formattedFollowupTime}
                </span>
              )}
              <Textarea value={messageText} onChange={setMessageText} maxHeight={250}/>
            </div>
            <Button
              onClick={handleSubmit}
              className={styles.button}
              disabled={isLoadingSendMessage || (messageText.trim() === '' && selectedFiles.length === 0)}
              isLoading={isLoadingSendMessage}
            >
              <ArrowUpNew className={styles.arrowIcon} />
            </Button>
          </div>
          <div className={cn(styles.bottomInputBlock, styles.blockText)}>
            <div className={styles.fileInputPlus}>
              <label htmlFor="file-upload" className={styles.fileLabel}>
                <PlusBold className={styles.plusIcon} />
              </label>
            </div>
            <SecondCrmInput />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrmContact;
