import { useInfiniteQuery } from '@tanstack/react-query';
import { INITIAL_LIMIT, INITIAL_PAGE, useLazyGetCrmContactsV2Query } from 'services/crm';

export const useGetInfiniteCrmV2 = (
  pageSize: number | undefined
) => {
  const [getCrmContactsV2] = useLazyGetCrmContactsV2Query();

  return useInfiniteQuery({
    queryKey: ['crm-v2'],
    queryFn: async ({ pageParam = INITIAL_PAGE }) => {
      const res = await getCrmContactsV2({
        page: pageParam,
        limit: pageSize || INITIAL_LIMIT,
      }).unwrap() as any;
      return { pageParam, ...res };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.last
        ? undefined
        : (lastPage.pageParam || INITIAL_PAGE) + 1;
    },
    onSuccess: (data) => {
      const pages = data.pages;
      if (!Array.isArray(pages)) return;
    },
    keepPreviousData: true,
    refetchInterval: 60000,
  });
}; 