import styles from './InfiniteCrmList.module.scss';

import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { InfiniteList } from 'components/InfiniteList';
import CrmContactCard from 'components/CrmContactCard';

import { useGetInfiniteCrm } from './api/useGetInfiniteCrm';

import SkeletonWithWrapper from 'shared/ui/SkeletonWithWrapper';
import { getIndexOfLastListElement } from 'shared/lib/arrays';
import { useQueryClient } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { BusinessType, FunnelStatus, Readiness, ResponseUrgency } from 'dtos';

export type InfiniteCrmListProps = {
  businessType?: BusinessType[];
  readiness?: Readiness[];
  responseUrgency?: ResponseUrgency[];
  funnelStatus?: FunnelStatus[];
  tabIndex: number;
}

export function InfiniteCrmList({ businessType, responseUrgency, readiness, funnelStatus, tabIndex }: InfiniteCrmListProps): JSX.Element {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize');
  const pageSizeNum = !isNaN(Number(pageSize)) ? Number(pageSize) : undefined;

  const { data, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetInfiniteCrm(businessType, readiness, responseUrgency, funnelStatus, pageSizeNum);

  const contacts = useMemo(() => {
    const allContacts = data?.pages.map((p) => p.content) || [];
    return allContacts.flat();
  }, [data?.pages]);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  useEffect(() => {
    queryClient.removeQueries(['crm'], { exact: false });
  }, []);

  useEffect(() => {
    funnelStatus && localStorage.setItem('funnelStatus', funnelStatus?.join(','));
    businessType && localStorage.setItem('businessType', businessType?.join(','));
    readiness && localStorage.setItem('readiness', readiness?.join(','));
    responseUrgency && localStorage.setItem('responseUrgency', responseUrgency?.join(','));
    localStorage.setItem('crmTabIndex', tabIndex.toString());
  }, [funnelStatus, businessType, readiness, responseUrgency, tabIndex]);

  const indexOfLastListElement = getIndexOfLastListElement(contacts);

  return (
    <InfiniteList
      limit={20}
      loading={isLoading}
      loadingNextPage={isFetchingNextPage}
      listClassName={styles.contacts}
      loaderItem={<SkeletonWithWrapper height={64} />}
      emptyListPlaceholder={t('page.crm.emptyTab')}
    >
      {contacts.map((c, idx) => (
        <li key={c.id} ref={indexOfLastListElement === idx ? ref : undefined}>
            <CrmContactCard contact={c} />
        </li>
      ))}
    </InfiniteList>
  );
}
