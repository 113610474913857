import styles from './CrmContactCard.module.scss';

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { Avatar, AvatarProps, AvatarSize } from 'shared/ui/Avatar/Avatar';
import { formatDate, getUserPhoto } from 'utils';
import LeadEmoji from 'components/LeadEmoji';

export interface CrmContactCardProps {
  contact: any;
  onClick?: (userDto: any) => void;
  isSimplified?: boolean;
}

function CrmContactCard({ contact, onClick, isSimplified }: CrmContactCardProps): JSX.Element | null {
  const userName = useMemo(() => contact.userName, [contact]) || contact.name;
  const phoneNumber = useMemo(() => contact.phoneNumber, [contact]);
  const isIgnored = useMemo(() => contact.isIgnored, [contact]) || false;
  const userPhoto = useMemo(() => getUserPhoto(contact), [contact]);
  const lastMessageText = useMemo(() => contact.lastMessageText, [contact]);
  const lastMessageDate = useMemo(() => contact.lastMessageDate, [contact]);
  const navigate = useNavigate();

  const avatarProps = useMemo<AvatarProps>(() => {
    return {
      username: userName,
      profilePictureUrl: userPhoto,
      size: AvatarSize.Payment,
    };
  }, [userName, userPhoto, isIgnored]);

  const onCardClick = () => {
    if (onClick) {
      onClick(contact);
    } else {
      navigate(`/crm/${contact.id}`);
    }
  };

  const hasLastMessage = lastMessageText !== undefined && lastMessageDate !== undefined;

  return (
    <div onClick={onCardClick} className={cn(styles.card, { [styles.ignore]: isIgnored })}>
      <div className={styles.cardWrapper}>
        <Avatar {...avatarProps} />

        <div className={styles.wrap}>
          <div className={styles.inner}>
            <div className={styles.username}>{phoneNumber}</div>
          </div>

          {!isSimplified && (
            <LeadEmoji
              responseUrgency={contact.responseUrgency}
              readiness={contact.readiness}
              isAnalyzing={contact.isAnalyzing}
              businessType={contact.businessType}
              followUp={!!contact && contact.followupMessage !== undefined && contact.operatorResponse === undefined}
            />
          )}
        </div>
      </div>
      {!isSimplified && hasLastMessage && (
        <div className={styles.text}>
          <div className={styles.message}>{lastMessageText}</div>
          <div className={styles.date}>
            {formatDate(new Date(lastMessageDate))}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(CrmContactCard);
