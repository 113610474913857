import React from 'react';

import { Layout } from 'components/Layout/Layout';
import { CrmHeader, CrmContent } from './components';

function CrmPage(): JSX.Element {

  return (
    <Layout>
      <CrmHeader />

      <CrmContent />
    </Layout>
  );
}

export default CrmPage;
