import React, { useState } from 'react';

import { InfiniteCrmList } from 'modules/InfiniteCrmList';
import { InfiniteCrmListV2 } from 'modules/InfiniteCrmListV2';

import { Layout } from 'components/Layout/Layout';
import { Tab } from '@headlessui/react';
import styles from './CrmContent.module.css';
import { StyledTab } from 'shared/ui/Tab/Tab';
import { FunnelStatus } from 'dtos';
import { useTranslation } from 'react-i18next';

type CrmContentProps = {
  searchString?: string;
};

export function CrmContent(props: CrmContentProps): JSX.Element {
  const { t } = useTranslation();
  const currentTabIndexStr = localStorage.getItem('crmTabIndex');
  const currentTabIndex = parseInt(currentTabIndexStr || '0');
  const [selectedIndex, setSelectedIndex] = useState(currentTabIndex);

  return (
    <Layout.Content unsetBottomPadding unsetTopPadding >

      <Tab.Group
        selectedIndex={selectedIndex}
        onChange={(index) => setSelectedIndex(index)}
      >
        <Tab.List className={styles.tabsContainer}>
          <StyledTab>{t('page.crm.myContactsTab')}</StyledTab>
          <StyledTab>{t('page.crm.allContactsTab')}</StyledTab>
        </Tab.List>

        <Tab.Panels className={styles.panels}>
          <Tab.Panel>
            <InfiniteCrmListV2 tabIndex={0}/>
          </Tab.Panel>

          <Tab.Panel>
            <InfiniteCrmList tabIndex={1}/>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Layout.Content>
  );
}
